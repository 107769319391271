module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":992,"disableBgImageOnAlpha":true,"backgroundColor":"none","withWebp":true,"tracedSVG":true,"quality":77}}],"defaultLayouts":{"posts":"/vercel/path0/src/components/LayoutPost.js","default":"/vercel/path0/src/components/LayoutDefault.js"},"extensions":[".mdx"],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":992,"disableBgImageOnAlpha":true,"backgroundColor":"none","withWebp":true,"tracedSVG":true,"quality":77,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"loading":"lazy","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"benjmn","short_name":"benjmn","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a0b692d99b50565cbdcfca30d35d3842"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
